let form = document.getElementById("priorityForm");
form.addEventListener("submit", function (event) {
    if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
    }
    form.classList.add("was-validated");

    if(form.checkValidity()) {
        event.preventDefault();
        const redirectURL = new URL(form.action);
        let currentParams = new URL(document.location).searchParams;

        let priorityCode = document.getElementById('priorityCode').value;
        if(priorityCode.toLowerCase() !== "guest") {
            redirectURL.searchParams.append("priorityCode", priorityCode)
        }
        if(currentParams.has("tfn")) {
            redirectURL.searchParams.append("tfn", currentParams.get("tfn"))
        }
        // Add all UMTs params so to next page URL
        if(currentParams.has("utm_campaign")) {
            redirectURL.searchParams.append("utm_campaign", currentParams.get("utm_campaign"));
        }
        if(currentParams.has("utm_source")) {
            redirectURL.searchParams.append("utm_source", currentParams.get("utm_source"));
        }
        if(currentParams.has("utm_medium")) {
            redirectURL.searchParams.append("utm_medium", currentParams.get("utm_medium"));
        }
        if(currentParams.has("utm_content")) {
            redirectURL.searchParams.append("utm_content", currentParams.get("utm_content"));
        }
        window.location.href = redirectURL;
    }
});